<header class="header">
  <img class="logo-image" src="assets/images/logo-desktop.webp" alt="Logo"/>
</header>

<div class="request-delete-wrapper">
  <h1>{{'REQUEST_DELETE.TITLE' | translate}}</h1>
  <!-- FORMULARIO DE LOGIN -->
  <form (ngSubmit)="onSubmit()" #deleteForm="ngForm" class="login-form">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>{{'REQUEST_DELETE.EMAIL' | translate}}</mat-label>
      <input matInput type="email" [(ngModel)]="email" name="email" required/>
      <mat-error *ngIf="true">
        {{ 'WARNS.FIELD_REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <div class="actions">
      <button mat-raised-button color="primary" type="button" routerLink="/home">
        {{'CRUD.CANCEL' | translate}}
      </button>
      <button mat-raised-button color="accent" type="submit" [disabled]="deleteForm.invalid">
        {{'REQUEST_DELETE.SUBMIT' | translate}}
      </button>
    </div>
  </form>
</div>

<app-footer class="fullscreen"></app-footer>
