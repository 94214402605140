<h1>{{'CREDENTIAL_DETAIL.TITLE' | translate}}</h1>

<form [formGroup]="credentialForm" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{'CREDENTIAL_DETAIL.NAME' | translate}}</mat-label>
    <input matInput formControlName="serviceName" required/>
    <mat-error *ngIf="credentialForm.get('name')?.invalid">
      <span *ngIf="credentialForm.get('name')?.hasError('required')">{{'WARNS.FIELD_REQUIRED' | translate}}</span>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{'CREDENTIAL_DETAIL.URL' | translate}}</mat-label>
    <input matInput formControlName="serviceUrl"/>
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{'CREDENTIAL_DETAIL.USERNAME' | translate}}</mat-label>
    <input matInput formControlName="username"/>
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{'CREDENTIAL_DETAIL.PASSWORD' | translate}}</mat-label>
    <!-- https://fireflysemantics.medium.com/angular-material-password-field-with-visibilitytoggle-d5342f97afbe -->
    <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'"/>
    <mat-icon matSuffix
              (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
  </mat-form-field>


  <h2>{{'CREDENTIAL_DETAIL.CATEGORIES' | translate}}</h2>

  <div class="category-buttons">
    <button
      mat-stroked-button
      type="button"
      *ngFor="let category of categories"
      [class.selected]="selectedCategories.includes(category.id)"
      (click)="toggleCategorySelection(category.id)">
      {{ category.name }}
    </button>
  </div>

  <div class="actions">
    <button mat-raised-button color="primary" type="button" (click)="onCancel()">{{'CRUD.CANCEL' | translate}}</button>
    <button mat-raised-button color="accent" type="submit"
            [disabled]="credentialForm.invalid">{{'CRUD.ACCEPT' | translate}}</button>
  </div>
</form>
