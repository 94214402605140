<h1>{{'CREDENTIAL_LIST.TITLE' | translate}}</h1>

<div class="table-content">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <!-- Columna de selección -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="toggleSelectAll($event)"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox [(ngModel)]="row.selected" (ngModelChange)="toggleRowSelection(row)"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="serviceName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'CREDENTIAL_LIST.SERVICE_NAME' | translate}} </th>
      <td mat-cell *matCellDef="let row" (click)="viewDetails(row.id)"> {{ row.serviceName }} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> {{'CRUD.ACTIONS' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        <button class="actionButton" mat-stroked-button (click)="copyPassword(row.id)">
        <span class="innerButton">
          <fa-icon [icon]="faCopy"></fa-icon>
          <span class="label">{{'CREDENTIAL_LIST.COPY_PASS' | translate}}</span>
        </span>
        </button>
        <button class="actionButton" mat-stroked-button (click)="viewDetails(row.id)">
      <span class="innerButton">
        <fa-icon [icon]="faEye"></fa-icon>
        <span class="label">{{'CRUD.VIEW_DETAIL' | translate}}</span>
      </span>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<div class="actions">
  <button class="actionButton" mat-raised-button color="primary" (click)="selectAll()">
    <span class="innerButton">
      <fa-icon [icon]="faBorderAll"></fa-icon>
      <span class="label">{{'CRUD.SELECT_ALL' | translate}}</span>
    </span>
  </button>
  <button class="actionButton" mat-raised-button color="primary" (click)="deselectAll()">
    <span class="innerButton">
      <fa-icon [icon]="faBorderNone"></fa-icon>
      <span class="label">{{'CRUD.SELECT_NONE' | translate}}</span>
    </span>
  </button>
  <button class="actionButton" mat-raised-button color="warn" (click)="deleteSelected()">
    <span class="innerButton">
      <fa-icon [icon]="faTrashCan"></fa-icon>
      <span class="label">{{'CRUD.DELETE' | translate}}</span>
    </span>
  </button>
  <button class="actionButton" mat-raised-button color="accent" (click)="editSelected()"
          [disabled]="selectedCredentials.size !== 1">
    <span class="innerButton">
      <fa-icon [icon]="faPenToSquare"></fa-icon>
      <span class="label">{{'CRUD.EDIT' | translate}}</span>
    </span>
  </button>
  <button class="actionButton" mat-raised-button color="accent" (click)="addCredential()">
    <span class="innerButton">
      <fa-icon [icon]="faPlus"></fa-icon>
      <span class="label">{{'CRUD.ADD_NEW' | translate}}</span>
    </span>
  </button>
</div>
