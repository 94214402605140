<h1>{{'CATEGORY_LIST.TITLE' | translate}}</h1>

<div class="category-buttons" *ngIf="currentView === 'home'">
  <button mat-stroked-button type="button"
          *ngFor="let category of dataSource.data"
          [class.selected]="category.selected"
          (click)="onCategoryClick(category.id)">
    {{ category.name }}
  </button>
</div>

<div class="table-wrapper" *ngIf="currentView === 'category-list'">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <!-- Columna de selección -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="toggleSelectAll($event)"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox [(ngModel)]="row.selected" (ngModelChange)="toggleRowSelection(row)"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CATEGORY_LIST.NAME' | translate}}</th>
      <td mat-cell *matCellDef="let row" (click)="viewDetails(row.id)"> {{ row.name }} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{'CRUD.ACTIONS' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <button class="actionButton" mat-stroked-button (click)="viewDetails(row.id)">
          <span class="innerButton">
            <fa-icon [icon]="faEye"></fa-icon>
            <span class="label">{{'CRUD.VIEW_DETAIL' | translate}}</span>
          </span>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div class="actions">
    <button class="actionButton" mat-raised-button color="primary" (click)="selectAll()">
    <span class="innerButton">
      <fa-icon [icon]="faBorderAll"></fa-icon>
      <span class="label">{{'CRUD.SELECT_ALL' | translate}}</span>
    </span></button>
    <button class="actionButton" mat-raised-button color="primary" (click)="deselectAll()">
    <span class="innerButton">
      <fa-icon [icon]="faBorderNone"></fa-icon>
      <span class="label">{{'CRUD.SELECT_NONE' | translate}}</span>
    </span>
    </button>
    <button class="actionButton" mat-raised-button color="warn" (click)="deleteSelected()">
    <span class="innerButton">
      <fa-icon [icon]="faTrashCan"></fa-icon>
      <span class="label">{{'CRUD.DELETE' | translate}}</span>
    </span>
    </button>
    <button class="actionButton" mat-raised-button color="accent" (click)="editSelected()"
            [disabled]="selectedCategories.size !== 1">
    <span class="innerButton">
      <fa-icon [icon]="faPenToSquare"></fa-icon>
      <span class="label">{{'CRUD.EDIT' | translate}}</span>
    </span>
    </button>
    <button class="actionButton" mat-raised-button color="accent" (click)="addCredential()">
    <span class="innerButton">
      <fa-icon [icon]="faPlus"></fa-icon>
      <span class="label">{{'CRUD.ADD_NEW' | translate}}</span>
    </span>
    </button>
  </div>
</div>
