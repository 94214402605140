<form (submit)="onSearch($event)">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{ 'BROWSER.FIND' | translate }}</mat-label>
    <input matInput type="text" [(ngModel)]="searchTerm" name="search"/>
  </mat-form-field>
  <button class="actionButton" mat-raised-button color="primary" type="submit">
    <span class="innerButton">
      <fa-icon [icon]="faMagnifyingGlass"></fa-icon><span class="label">{{ 'BROWSER.FIND' | translate }}</span>
    </span>
  </button>
</form>
