<h1>{{'USER_PROFILE.TITLE' | translate}}</h1>
<form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{'USER_PROFILE.NAME' | translate}}</mat-label>
    <input matInput formControlName="name"/>
    <mat-error *ngIf="profileForm.get('name')?.invalid">
      <span *ngIf="profileForm.get('name')?.hasError('required')">{{'WARNS.FIELD_REQUIRED' | translate}}</span>
      <span *ngIf="profileForm.get('name')?.hasError('minlength')">{{'WARNS.FIELD_CHARS_2_XX' | translate}}</span>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{'USER_PROFILE.SURNAME' | translate}}</mat-label>
    <input matInput formControlName="surname"/>
    <mat-error *ngIf="profileForm.get('surname')?.invalid">
      <span *ngIf="profileForm.get('surname')?.hasError('required')">{{'WARNS.FIELD_REQUIRED' | translate}}</span>
      <span *ngIf="profileForm.get('surname')?.hasError('minlength')">{{'WARNS.FIELD_CHARS_2_XX' | translate}}</span>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{'USER_PROFILE.EMAIL' | translate}}</mat-label>
    <input matInput type="email" formControlName="email"/>
    <mat-error *ngIf="profileForm.get('email')?.invalid">
      <span *ngIf="profileForm.get('email')?.hasError('required')">{{'WARNS.FIELD_REQUIRED' | translate}}</span>
      <span *ngIf="profileForm.get('email')?.hasError('email')">{{'WARNS.FIELD_EMAIL_FORMAT' | translate}}</span>
    </mat-error>
  </mat-form-field>

  <div class="actions">
    <button mat-raised-button color="primary" type="button" (click)="onCancel()">{{'CRUD.CANCEL' | translate}}</button>
    <button mat-raised-button color="accent" type="submit"
            [disabled]="profileForm.invalid">{{'CRUD.ACCEPT' | translate}}</button>
  </div>
</form>
