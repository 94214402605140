<h1>{{'CHANGE_PASSWORD.TITLE' | translate}}</h1>
<form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{'CHANGE_PASSWORD.PASSWORD' | translate}}</mat-label>
    <input matInput [type]="hidePassword_oldPassword ? 'password' : 'text'" formControlName="oldPassword"
           (input)="onInputChange()"/>
    <mat-icon matSuffix
              (click)="hidePassword_oldPassword = !hidePassword_oldPassword">{{hidePassword_oldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
    <mat-error
      *ngIf="changePasswordForm.get('oldPassword')?.hasError('required')">{{'WARNS.FIELD_REQUIRED' | translate}}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{'CHANGE_PASSWORD.NEW_PASSWORD' | translate}}</mat-label>
    <input matInput [type]="hidePassword_newPassword ? 'password' : 'text'" formControlName="newPassword"/>
    <mat-icon matSuffix
              (click)="hidePassword_newPassword = !hidePassword_newPassword">{{hidePassword_newPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
    <mat-error
      *ngIf="changePasswordForm.get('newPassword')?.hasError('required')">{{'WARNS.FIELD_REQUIRED' | translate}}</mat-error>
    <mat-error
      *ngIf="changePasswordForm.get('newPassword')?.hasError('minlength')">{{'WARNS.FIELD_CHARS_6_64' | translate}}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>{{'CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD' | translate}}</mat-label>
    <input matInput [type]="hidePassword_confirmNewPassword ? 'password' : 'text'"
           formControlName="confirmNewPassword"/>
    <mat-icon matSuffix
              (click)="hidePassword_confirmNewPassword = !hidePassword_confirmNewPassword">{{hidePassword_confirmNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
    <mat-error
      *ngIf="changePasswordForm.hasError('passwordsMismatch')">{{'WARNS.PASSWORD_NOT_MATCH' | translate}}</mat-error>
  </mat-form-field>

  <div *ngIf="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>

  <div class="actions">
    <button mat-raised-button color="primary" type="button" (click)="onCancel()">{{'CRUD.CANCEL' | translate}}</button>
    <button mat-raised-button color="accent" type="submit"
            [disabled]="changePasswordForm.invalid">{{'CRUD.ACCEPT' | translate}}</button>
  </div>
</form>
