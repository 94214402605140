<app-browser (search)="handleSearch($event)"></app-browser>

<div id="split-container">
  <div class="split top-pane">
    <app-category-list (filterChange)="handleCategoryFilter($event)"></app-category-list>
  </div>
  <div class="split bottom-pane">
    <app-credential-list></app-credential-list>
  </div>
</div>
