<div class="header">
  <img class="logo-image" src="assets/images/logo-desktop.webp" alt="Logo"/>
</div>

<div class="activate-wrapper">
  <h1>{{'ACTIVATE.TITLE' | translate}}</h1>
  <p>{{'ACTIVATE.RESULT_OK' | translate}}</p>
  <div class="actions">
    <button mat-raised-button color="primary" type="button" routerLink="/home">
      {{'ACTIVATE.START_SESSION' | translate}}
    </button>
  </div>

</div>

<app-footer class="fullscreen"></app-footer>
